// WorkSans - Thin - Normal
@font-face {
    font-family: "WorkSans";
    src: url('../fonts/WorkSans-Thin.ttf') format("truetype");
    font-weight: 100;
    font-style: normal;
}

// WorkSans - Thin - Italic
@font-face {
    font-family: "WorkSans";
    src: url('../fonts/WorkSans-ThinItalic.ttf') format("truetype");
    font-weight: 100;
    font-style: italic;
}

// WorkSans - Extra Light - Normal
@font-face {
    font-family: "WorkSans";
    src: url('../fonts/WorkSans-ExtraLight.ttf') format("truetype");
    font-weight: 200;
    font-style: normal;
}

// WorkSans - Extra Light - Italic
@font-face {
    font-family: "WorkSans";
    src: url('../fonts/WorkSans-ExtraLightItalic.ttf') format("truetype");
    font-weight: 200;
    font-style: italic;
}

// WorkSans - Light - Normal
@font-face {
    font-family: "WorkSans";
    src: url('../fonts/WorkSans-Light.ttf') format("truetype");
    font-weight: 300;
    font-style: normal;
}

// WorkSans - Light - Italic
@font-face {
    font-family: "WorkSans";
    src: url('../fonts/WorkSans-LightItalic.ttf') format("truetype");
    font-weight: 300;
    font-style: italic;
}

// WorkSans - Regular - Normal
@font-face {
    font-family: "WorkSans";
    src: url('../fonts/WorkSans-Regular.ttf') format("truetype");
    font-weight: 400;
    font-style: normal;
}

// WorkSans - Regular - Italic
@font-face {
    font-family: "WorkSans";
    src: url('../fonts/WorkSans-RegularItalic.ttf') format("truetype");
    font-weight: 400;
    font-style: italic;
}

// WorkSans - Medium - Normal
@font-face {
    font-family: "WorkSans";
    src: url('../fonts/WorkSans-Medium.ttf') format("truetype");
    font-weight: 500;
    font-style: normal;
}

// WorkSans - Medium - Italic
@font-face {
    font-family: "WorkSans";
    src: url('../fonts/WorkSans-MediumItalic.ttf') format("truetype");
    font-weight: 500;
    font-style: italic;
}

// WorkSans - SemiBold - Normal
@font-face {
    font-family: "WorkSans";
    src: url('../fonts/WorkSans-SemiBold.ttf') format("truetype");
    font-weight: 600;
    font-style: normal;
}

// WorkSans - SemiBold - Italic
@font-face {
    font-family: "WorkSans";
    src: url('../fonts/WorkSans-SemiBoldItalic.ttf') format("truetype");
    font-weight: 600;
    font-style: italic;
}

// WorkSans - Bold - Normal
@font-face {
    font-family: "WorkSans";
    src: url('../fonts/WorkSans-Bold.ttf') format("truetype");
    font-weight: 700;
    font-style: normal;
}

// WorkSans - Bold - Italic
@font-face {
    font-family: "WorkSans";
    src: url('../fonts/WorkSans-BoldItalic.ttf') format("truetype");
    font-weight: 700;
    font-style: italic;
}

// WorkSans - ExtraBold - Normal
@font-face {
    font-family: "WorkSans";
    src: url('../fonts/WorkSans-ExtraBold.ttf') format("truetype");
    font-weight: 800;
    font-style: normal;
}

// WorkSans - ExtraBold - Italic
@font-face {
    font-family: "WorkSans";
    src: url('../fonts/WorkSans-ExtraBoldItalic.ttf') format("truetype");
    font-weight: 800;
    font-style: italic;
}

// WorkSans - Black - Normal
@font-face {
    font-family: "WorkSans";
    src: url('../fonts/WorkSans-Black.ttf') format("truetype");
    font-weight: 900;
    font-style: normal;
}

// WorkSans - Black - Italic
@font-face {
    font-family: "WorkSans";
    src: url('../fonts/WorkSans-BlackItalic.ttf') format("truetype");
    font-weight: 900;
    font-style: italic;
}