/*
 * Booststrap Variable Overrides
 */

// Colours
$primary: #BB29BB;
$secondary: #41B6E6;
$gray-100: #F8F8F8;
$gray-200: #F2F2F2;
$gray-300: #EAEAEA;
$gray-900: #1B1B1B;
$black: #000000;

// Typography
$font-family-sans-serif: "WorkSans", "Arial", sans-serif;
$font-size-base: 1.125rem; // 18px

$h1-font-size: 60px;
$h2-font-size: 48px;
$h3-font-size: 36px;
$h4-font-size: 30px;
$h5-font-size: 24px;
$h6-font-size: 18px;

$headings-font-weight:        700;
$headings-line-height:        1.2;

// Links
$link-color: $gray-900;
$link-hover-color: $primary;

$navbar-toggler-padding-y:          .5rem;
$navbar-toggler-padding-x:          .5rem;
$navbar-toggler-focus-width:        0;

$navbar-light-color: $black;
$navbar-light-hover-color: $primary;
$navbar-light-active-color: $black;
$navbar-light-disabled-color: rgba(0,0,0,0.7);

$navbar-light-toggler-icon-bg: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40"><rect width="40" height="40" fill="#{$gray-100}" opacity="0"/><g transform="translate(-340 -9)"><rect width="20" height="2" stroke="#{$gray-900}" transform="translate(350 20)"/><rect width="10" height="2" stroke="#{$gray-900}" transform="translate(360 28)"/><rect width="20" height="2" stroke="#{$gray-900}" transform="translate(350 36)"/></g></svg>');

// Breadcrumbs
$breadcrumb-font-size:              1.1rem;
$breadcrumb-padding-y:              0.25rem;
$breadcrumb-padding-x:              0.5rem;
$breadcrumb-item-padding-x:         .5rem;
$breadcrumb-margin-bottom:          1rem;
$breadcrumb-bg:                     $black;
$breadcrumb-divider-color:          $secondary;
$breadcrumb-active-color:           $secondary;
$breadcrumb-divider:                quote("/");
$breadcrumb-divider-flipped:        $breadcrumb-divider;
$breadcrumb-border-radius:          null;

// Forms
$form-select-bg: $gray-100;
$form-select-indicator-color: $black;
$form-select-indicator: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='17' height='10' viewBox='0 0 17 10'><path d='M7.738.9A1,1,0,0,1,9.262.9L15.6,8.352A1,1,0,0,1,14.838,10H2.162A1,1,0,0,1,1.4,8.352Z' transform='translate(17 10) rotate(180)' fill='#{$form-select-indicator-color}' /></svg>");